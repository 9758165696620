@font-face {
  font-family: avant;
  src: url(assets/79f19b715d5f0c29caa0e20c85dd785c.eot);
  src: url(assets/e3ca1c9bfed18e99bbe0aec0110de53c.ttf);
  src: url(assets/0b189ab42a630d0f758254b2443d7550.woff);
  src: url(assets/ecb55dcf8830b3bbb9a7970b4d7bcedc.woff2);
}

.headerTitle {
  font-family: avant !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 32px !important;
  color: #02081b;
  align-items: center;
}

.loadingScreen .loadingText {
  font-family: avant;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
}

.sectiontitle {
  font-family: avant;
  font-weight: normal;
}

.kioskbody {
  height: 85vh;
  width: 100%;
}

.kiosknameTitle {
  font-family: Roboto;
  font-size: 34px;
  text-align: left;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
}

.setupTitle {
  font-family: Roboto;
  font-size: 16px;
  text-align: right;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.6);
}

.kiosknamedesc {
  font-family: Roboto;
  font-size: 16px;
  margin-top: 13px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.6);
}

.apptitle {
  font-family: avant !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 32px !important;
  margin-top: 35px !important;
  margin-bottom: 60px !important;
  color: #02081b;
  align-items: center;
}

.virtualTitle {
  font-family: avant !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 32px !important;
  margin-top: 30px !important;
  margin-bottom: 60px !important;
  color: #02081b;
  text-align: center;
}

.homepagetitle1 {
  font-family: avant;
  font-style: normal;
  font-size: 20px;
  line-height: 28px;
  color: #56657f;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.homepagetitle2 {
  font-family: avant;
  font-style: normal;
  font-size: 30px;
  line-height: 38px;
  color: #02081b;
  text-align: center;
  margin-bottom: 5px;
}

.orglogo {
  width: 96px;
  height: 96px;
  margin-bottom: 20px;
}

.Toastify__toast--success {
  background: #08bd72 !important;
}

.checkinButton {
  position: absolute !important;
  width: 140px;
  height: 40px;
  right: 24px;
  background: #147df4 !important;
  border-radius: 4px !important;
  top: 24px;
  color: #ffffff !important;
  font-weight: bold !important;
  font-size: 11px !important;
  letter-spacing: 0.05em;
  z-index: 9;
  text-transform: capitalize !important;
}

.card {
  font-weight: bold !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 2 !important;
  line-height: 30px !important;
  max-height: 60px !important;
  word-break: break-word !important;
}

.MuiTypography-h5-287 {
  color: "#E1EDFD" !important;
}

.sidebar {
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  width: 96px;
  height: 100vh;
  z-index: 1;
  background: #ffffff;
  box-shadow: 0px 40px 80px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.16);
}

.create_waiver_container {
  border-style: groove;
}

.demo-editor {
  height: 460px !important;
  background: white;
}

.rdw-editor-toolbar {
  background: #bbd6fb !important;
}

.rdw-option-wrapper,
.rdw-dropdown-wrapper {
  background: transparent !important;
  border: 0px !important;
}

.mui-ripple-circle {
  display: none;
}

#waivername-helper-text {
  color: red;
  font-family: Lato !important;
  font-style: normal !important;
  line-height: normal !important;
  font-size: 14px;
  font-weight: normal;
}

.active-field:hover {
  background: #147df4 !important;
  color: white !important;
}

.privacy_policy {
  background: #f0f7fe;
  padding-left: 70px;
  padding-right: 70px;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  padding-bottom: 35px;
  padding-top: 1px;
}

.icon {
  float: right;
  color: #a6b8cc !important;
  margin-top: 25px !important;
}

.check-in-image {
  padding-right: 10px;
  padding-top: 3px;
}

.active_div {
  background-color: #147df4;
  height: 65px;
  text-align: center;
  cursor: pointer;
}

.inactive_div {
  height: 65px;
  text-align: center;
  cursor: pointer;
}

.logout {
  height: 65px !important;
  text-align: center;
}

@media only screen and (min-height: 625px) {
  .logout {
    position: absolute;
    bottom: 0;
    width: 96px;
  }
}

.activenav_name {
  font-family: Lato;
  font-size: 12px;
  line-height: normal;
  text-align: center;
  color: #f7faff;
}

.inactivenav_name {
  font-family: Lato;
  font-size: 12px;
  line-height: normal;
  text-align: center;
  color: #687fa3;
}

.page_header {
  width: 100%;
  margin-top: 7px !important;
}

.card_container {
  height: calc(100vh - 200px);
  overflow: auto;
  margin-top: 18px;
  padding-left: 100px;
}

.card_container_form {
  height: calc(100vh - 140px);
  overflow: auto;
  margin-top: 18px;
  padding-left: 100px;
}

.card_container_kiosk {
  height: calc(100vh - 240px);
  overflow: auto;
  margin-top: 35px;
  padding-left: 100px;
}

.appbar {
  font-family: Lato !important;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 14px;
  color: #33415c !important;
  background-color: #ffffff !important;
  padding: 0 40px;
  min-width: 1000px;
}

.main_background {
  padding: 40px;
  background: #f5f6f8;
  height: calc(100vh - 238px);
  overflow-y: scroll;
}

.activity-padding {
  padding-left: 229px !important;
  padding-right: 229px !important;
}

.header {
  margin-top: -22px;
}

.create_title {
  padding: 30px 0 !important;
}

.create_textfield {
  text-align: center;
  padding-top: 18px !important;
}

.create_close {
  text-align: right;
  padding: 30px 0 !important;
}

.footer {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 14px;
  color: #33415c;
  background-color: white;
  padding: 0px 40px;
  height: 64px;
}

body {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
h7 {
  font-family: Lato;
  font-style: normal;
  color: #33415c;
}

h1 {
  font-weight: bold;
  font-size: 48px;
  line-height: 63px;
}

h2 {
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
}

h3 {
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
}

h4 {
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
}

h5 {
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
}

h6 {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
}

div::-webkit-scrollbar-track,
ul::-webkit-scrollbar-track,
main::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 5px;
}

div::-webkit-scrollbar,
ul::-webkit-scrollbar,
main::-webkit-scrollbar,
body::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

div::-webkit-scrollbar-thumb,
ul::-webkit-scrollbar-thumb,
main::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background-color: #147df4;
  border-radius: 5px;
}

.primary-background {
  background: linear-gradient(67.73deg, #0e69f1 1.08%, #20a2f9 100%);
}

.text-white {
  color: #ffffff !important;
}

.card_color {
  color: #012129 !important;
}

.margin_10 {
  margin-top: 10px !important;
}

.margin_top_62 {
  margin-top: 62px !important;
}

.align_left {
  text-align: left;
}

.back_arrow {
  position: absolute;
  margin-top: 28px;
  cursor: pointer;
}

.create-event-title {
  margin-bottom: 0px !important;
  margin-top: 30px !important;
}

.create-event-text {
  margin-left: 10px !important;
  margin-top: 4px;
}

.create-event-close {
  margin-left: 10px !important;
  margin-top: 0px !important;
}

.close-margin {
  padding-left: 13px !important;
  padding-top: 13px !important;
  cursor: pointer;
}

.margin_bottom0 {
  margin-bottom: 0px;
}

.top-event-label {
  margin-bottom: 0px;
  margin-top: 16px;
}

.check_in_title {
  padding: 0 8px;
}

.select_activity_title {
  color: red;
}

.check_in_textbox {
  width: 100%;
  margin: 0;
}

.image_padding {
  padding-left: 7px;
}

.send_merit_textfield {
  margin-top: 0;
  width: 100%;
}

.cursor_pointer {
  cursor: pointer;
}

.align_center {
  text-align: center;
}

.width_100 {
  width: 100%;
}

#menu-waiverList ul,
#menu-selectedEvent ul,
#menu-send_merits ul,
#menu-qualificationList ul {
  max-height: 155px;
  overflow: auto;
}

#menu-send_merits li {
  width: 345px;
}

#menu-merit ul {
  max-height: 155px;
  overflow: auto;
}

.grid_container {
  width: 100% !important;
}

.margin_0_top {
  margin-top: 0px !important;
}

.margin_10_bottom {
  margin-bottom: 10px !important;
}

.margin_30_bottom {
  margin-bottom: 30px !important;
}

.margin_top_28 {
  margin-top: 28px !important;
}

.margin_top_20 {
  margin-top: 20px !important;
}

.app_bar {
  justify-content: center;
  margin-left: 24px;
  height: 96px;
  padding-left: 80px;
  min-width: 1000px;
}

.virtualkioskTitle {
  font-family: Lato;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 32px !important;
  margin-top: 30px;
  line-height: 42px !important;
  color: #02081b;
  text-align: center;
}

.webkioskDesc {
  font-family: Lato;
  margin-bottom: 30px;
  margin-top: 10px;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 28px !important;
  color: #a6b8cc;
  text-align: center;
}

.virtualButton {
  width: "100%";
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-top: 60px;
}

.tab_container {
  height: calc(100vh);
  overflow: auto;
  padding-left: 100px;
}

.web_tab_container {
  height: calc(68vh);
  overflow: auto;
  padding-left: 100px;
}

.innerAppbar {
  height: 76px;
  bottom: 0px;
  position: absolute;
  width: 100%;
}

.text {
  margin-top: 0px;
  margin-bottom: 0px;
  align-items: center;
  font-size: 16px;
  text-align: center;
}

.buttonRight {
  text-align: right;
}

.buttonCenter {
  text-align: center;
}

.font-normal {
  font-weight: normal;
}

.arrow-right {
  float: right;
  padding-top: 30px;
}

.action {
  float: right;
}

.menu_item {
  color: #687fa3 !important;
  font-family: Lato !important;
  font-weight: bold !important;
}

.menu_item:hover {
  background-color: #f0f6fe !important;
  color: #0f6ff0 !important;
}

.close {
  color: #687fa3;
}

.error_background {
  background: #facdcf;
}

.content_width {
  width: 480px;
}

.table-download-spacing {
  padding: 16px;
}

.card-min-width {
  min-width: fit-content !important;
}

.virtual-kiosk-button {
  font-family: SF Compact Display Regular !important;
  margin-top: 30px;
}

.cont-scrl > div div:nth-child(2) {
  max-width: 48%;
  flex-basis: 48%;
  overflow-y: auto;
  height: 55px;
  padding-right: 20px;
  margin-right: 10px;
}

main {
  min-width: 1000px;
}

.sectionDescription {
  font-family: SF Compact Display Regular;
  color: #000000;
}

.homeContainer {
  height: 100vh;
  overflow: hidden;
}

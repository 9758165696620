.welcome_image {
  height: 170px;
}
.span_activity {
  margin-bottom: 13px;
}
.text_span {
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 28px !important;
  text-align: center !important;
  color: #a6b8cc !important;
}
.align-items-center {
  align-items: center;
}

.button_div {
  padding-top: 49px;
}
.welcome_button {
  background: #147df4 !important;
  border: 1px solid #1270dc !important;
  color: #ffffff !important;
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  letter-spacing: 0.05em !important;
  text-transform: capitalize !important;
  cursor: pointer;
  width: 200px;
  height: 44px;
}

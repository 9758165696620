.create_waiver_button {
  position: absolute !important;
  width: 174px;
  height: 40px;
  right: 175px;
  background: #ffffff;
  border: 1px solid #147df4 !important;
  box-sizing: border-box;
  border-radius: 4px;
  color: #147df4 !important;
  font-style: normal !important;
  font-weight: bold !important;
  line-height: normal !important;
  font-size: 11px !important;
  letter-spacing: 0.05em !important;
  text-transform: capitalize !important;
}

.create_waiver_container {
  border-style: groove;
  border: 2px solid #bbd6fb;
}
.create_waiver_modal {
  height: calc(100vh - 240px);
  overflow-y: scroll;
}
.waiver_container {
  text-align: center;
}
.waiver_name {
  left: 115px;
  bottom: 33px;
}
.name_container {
  background: white;
  margin-bottom: 4%;
  width: 115%;
  margin-left: -12%;
}
.new_form {
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: bold !important;
  line-height: normal !important;
  font-size: 14px !important;

  color: #33415c !important;
}
.error_div {
  margin-top: -2%;
  margin-left: 25%;
}
.editor_container {
  text-align: -webkit-left;
}
.info_label {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  font-size: 14px;

  color: #a6b8cc;
}
.preview_button {
  width: 132px;
  height: 44px;
  margin-left: 65% !important;
  margin-top: 2% !important;
  margin-bottom: 10% !important;
  background: #d3e6fd !important;
  color: #147df4 !important;
  font-style: normal !important;
  font-weight: bold !important;
  line-height: normal !important;
  font-size: 11px !important;
  letter-spacing: 0.05em !important;
  text-transform: capitalize !important;
}
.save_button {
  width: 132px;
  height: 44px;
  margin-left: 3% !important;
  margin-top: 2% !important;
  margin-bottom: 10% !important;
  color: #d3e6fd !important;
  background: #147df4 !important;
  font-style: normal !important;
  font-weight: bold !important;
  line-height: normal !important;
  font-size: 11px !important;
  letter-spacing: 0.05em !important;
  text-transform: capitalize !important;
}
.back_button {
  float: left;
  margin-left: 3%;
  cursor: pointer;
}

.title {
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: normal !important;

  color: #02081b;
}
.tag_container {
  background-color: #daebfd;
  padding: 10px;
  color: #687fa3;
}
.tags {
  padding: 10px;
  margin: 10px;
  background: #f0f7fe;
  border-radius: 5px;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 14px;
  cursor: pointer;
}
.waiver_subtitle {
  padding-top: 15px;
  width: 50% !important;
}
.waiver_subtitle_1 {
  float: right;
  width: 35%;
  margin-top: -53px !important;
}
.preview_label {
  padding: 30px 0 !important;
  text-align: center;
}
.preview_main {
  padding: 40px 160px 80px 160px;
}
#waiverName {
  width: 275px !important;
  padding: 16px 12px 10px !important;
}
.waiver_desc_text {
  font-family: Lato;
  font-size: 15px;
  color: #687fa3;
  margin: 0 !important;
}

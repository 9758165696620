.progress_bar {
  margin-left: 5%;
  width: 90%;
  border-bottom: solid 1px #e1edfd !important;
}
.progress_bar_steps {
  padding-right: 6%;
}
.grid {
  display: grid;
  padding: 1% 3%;
}
.grid_hr {
  box-shadow: 0px 1px 0px #dce5ef, 0px -1px 0px #dce5ef;
}
.paper {
  position: relative;
  top: 50%;
  left: 5%;
  width: 90%;
  font-size: 20px;
}
.buttons_div {
  position: absolute;
  bottom: 0;
  padding: 5%;
  width: 90%;
  margin-bottom: -3%;
}
.error-msg {
  font-family: Lato !important;
  font-style: normal !important;
  line-height: normal !important;
  color: #d0021b;
  font-size: 14px;
}
.highlight {
  height: 10px;
  padding: 5px 0 0 0;
}
.MuiSwitch-colorSecondary-295.MuiSwitch-checked-293 {
  color: #3f51b5 !important;
}
.MuiPrivateTabIndicator-colorSecondary-56 {
  background-color: #147df4 !important;
}
div#progress-stepper > div > div > div > span {
  background-color: #147df4 !important;
  height: 1px !important;
}
.steps {
  font-style: normal !important;
  font-weight: bold !important;
  line-height: normal;
  font-size: 12px !important;
  letter-spacing: 0.01em !important;
  text-transform: uppercase;
  min-width: 0px !important;
}
.active_steps {
  color: #147df4 !important;
}
.inactive_steps {
  color: #dce5ef !important;
}
.create_event_modal {
  background: #ffffff;
  box-shadow: 0px 1px 0px #dce5ef;
}
.create_activity_title {
  padding-left: 30px;
  color: white;
  font-weight: normal;
}
.margin_4_top {
  margin-top: 4px,
}
.paper_popup {
  position: relative;
}
.back_button {
  float: left;
  padding-top: 12px;
  padding-bottom: 12px;
}
.next_button {
  float: right;
  padding-right: 50px !important;
  padding-left: 50px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.padding_8_left {
  padding-left: 8px,
}
.step2_subtitle {
  float: left;
}
.step4_subtitle {
  float: left;
  margin-right: 5.7%;
}
.step5_subtitle {
  float: left;
}
.margin_50_bottom {
  margin-bottom: 50px;
}
.step2_switch1 {
  margin-top: 0.7% !important;
  float: left;
  margin-left: -2px !important;
}
.step2_switch2 {
  margin-top: -1.9% !important;
  float: left;
}
.step2_button {
  margin-top: -6% !important;
  float: right;
}
.margin_0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.marginbottom_0 {
  margin-bottom: 0px;
}
.marginbottom_10 {
  margin-bottom: 10px !important;
}
.message_subtitle {
  margin-top: 8px;
  margin-bottom: 15px;
  font-family: lato;
  font-size: 16px;
  font-weight: normal !important;
  line-height: 24px;
  max-width: 78%;
}
.time-zone-text {
  font-family: Lato;
  font-size: 12px;
  line-height: normal;
  color: #687fa3;
}

.no-password-sub-text {
  color: #d0021b !important;
}

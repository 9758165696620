.create_qualification_button {
  position: absolute;
  width: 174px;
  height: 40px;
  left: 65%;
  text-transform: capitalize;
  background: #ffffff;
  border: 1px solid #147df4 !important;
  box-sizing: border-box;
  border-radius: 4px;
  color: #147df4 !important;
  font-style: normal !important;
  font-weight: bold !important;
  line-height: normal !important;
  font-size: 10px !important;
  letter-spacing: 0.01em !important;
  text-transform: capitalize !important;
}
.AddMerit_Button {
  width: 100%;
  height: 60px;
  background: #ffffff !important;
  border-radius: 4px;
  color: #157ef4 !important;
  font-family: Lato !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
}
.AddMerit_Button:hover {
  background: #147df4 !important;
  color: white !important;
}

.ORMerit_Button {
  width: 100% !important;
  background: #ffffff !important;
  border: 1px solid #147df4 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  color: #157ef4 !important;
}
.ORMerit_Button:hover {
  background: #147df4 !important;
  color: white !important;
}
.ORMerit_Button:disabled {
  background: transparent !important;
  border: 1px solid #147df440 !important;
  color: #157ef440 !important;
}
.Text {
  background: linear-gradient(0deg, #ffffff, #ffffff), linear-gradient(0deg, #2a85f4, #2a85f4),
    linear-gradient(140.37deg, #147df4 0%, rgba(20, 125, 244, 0.3) 100%), #ffffff;
  box-shadow: 0px 1px 1px rgba(104, 127, 163, 0.32);
  border-radius: 4px;
  width: 100%;
  height: 90px;
  position: relative;
  padding: 20px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  color: #33415c;
}
.Text div {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.01em;
  color: #a6b8cc;
}
.Text img {
  position: absolute;
  right: 15px;
  top: 42%;
  cursor: pointer;
}
.paper_data {
  width: 300px !important;
  float: left !important;
  background: #d3e6fd !important;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  border-radius: 4px !important;
}

.save_merit_button {
  position: absolute !important;
  right: 30px !important;
  margin: 10px !important;
  width: 150px;
  /* width: 296px; */
  height: 44px;
  background: linear-gradient(0deg, #157ef4, #157ef4), linear-gradient(0deg, #2a85f4, #2a85f4),
    linear-gradient(140.37deg, #147df4 0%, rgba(20, 125, 244, 0.3) 100%), #ffffff;
  border-radius: 4px;
  color: #ffffff !important;
  font-family: Lato !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
}

.qualification-name {
  left: 115px;
  bottom: 33px;
}
#qualification-name-helper-text {
  color: red;
  font-family: Lato !important;
  font-style: normal !important;
  line-height: normal !important;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
  background: white;
}
.title {
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: normal !important;

  color: #02081b;
}
.or_text {
  text-align: center;
  color: #687fa3;
  margin: 12px 0;
}
.requirement_div {
  background: #d3e6fd;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  width: 360px;
  padding: 32px;
  margin: 30px 80px 0 0;
  flex: 0 0 auto;
  position: relative;
}
.requirement_label {
  margin-top: -64px;
  position: absolute;
  letter-spacing: 0.01em;
  color: #687fa3;
}
.common_text {
  color: #687fa3;
  font-weight: normal;
  margin-top: 30px;
}
.and_button {
  position: absolute;
  top: 70px;
  right: -55px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #687fa3;
}

.subtitle {
  color: #687fa3;
  font-weight: normal;
}
.button_container {
  min-height: calc(100vh - 354px);
  max-width: calc(100vw - 90px);
  overflow: scroll hidden;
  background-color: transparent;
  padding: 20px 0;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
}
.merit_dropdown {
  height: 30px !important;
  margin-top: 8px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.css-xp4uvy {
  font-family: Lato;
}
.create_qualification_modal {
  height: calc(100vh - 240px);
  overflow-y: scroll;
}
#qualification-name {
  width: 275px !important;
  padding: 16px 12px 10px !important;
}
.close_hover:hover {
  color: #687fa3;
}
.css-vj8t7z {
  height: 44px;
}
.link {
  font-weight: bold;
  color: #147df4;
  cursor: pointer;
}

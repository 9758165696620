.custom-button-1 {
  margin-right: 24px !important;
}

.custom-button-1 h6 {
  color: #a6b8cc !important;
  font-weight: bold;
  font-family: Lato;
  font-size: 18px;
  line-height: 22px;
}

.custom-button-1:hover h6 {
  color: #7b8a9c !important;
}

.custom-button-1.active h6 {
  color: #448ef3 !important;
  font-weight: bold;
  font-family: Lato;
  font-size: 18px;
  line-height: 22px;
}

.custom-button-1.active:hover h6 {
  color: #0d64da !important;
}

.events-actions {
  margin-top: -48px;
}

@media only screen and (max-width: 1130px) {
  .events-actions {
    margin-top: 10px;
  }
}

.title {
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: normal !important;
  color: #02081b;
}

.filters {
  margin-top: 18px;
  padding-left: 90px;
}

.noDataGrid {
  padding-left: 120px;
}

.searchContainer {
  width: 180px;
  background: #cfe4fd;
  height: 40px;
  border-radius: 24px;
}

.searchIcon {
  padding: 20;
}

.choose_merit_template {
  border: 1px solid #bbd6fb;
  border-radius: 4px;
  width: 416px;
}

.choose_merit_template .ant-select-selection {
  background: #f7faff !important;
}

.choose_merit_template .ant-select-dropdown-menu-item {
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
  color: #02081b;
}

.choose_merit_template .ant-select-selection-selected-value {
  font-family: Lato;
  font-size: 16px;
  color: #02081b;
}
/* //ant-select-dropdown-menu-item */

.react-bs-table table td,
.react-bs-table table th {
  overflow: unset;
}

.react-bs-container-body {
  overflow: unset;
}

.table {
  margin-bottom: 0px;
}

.checkinlog_cursor_pointer {
  margin-left: 7px;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.edit_member_name_modal {
  background: linear-gradient(67.73deg, #0e69f1 1.08%, #20a2f9 100%);
  opacity: 0.9;
}

.edit-member-modal-center {
  margin-top: 125px !important;
}

.common_buttons {
  width: 143px;
  height: 40px;
  border-radius: 4px !important;
  color: #ffffff !important;
  font-weight: bold !important;
  font-size: 16px !important;
  letter-spacing: 0.05em;
  text-transform: capitalize !important;
  z-index: 9;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.table-head-title span {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-size: 12px;
  color: #687fa3;
}

.table-head-title span:hover {
  color: #687fa3;
}

.table-content-title {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: #33415c;
  word-break: break-all;
}

.table-content-title-pointer {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: #33415c;
  word-break: break-all;
  cursor: pointer;
}

.table-content-subTitle {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 12px;
  color: #a6b8cc;
}

.table-content {
  position: relative;
}

.table-content a {
  color: #147df4;
  cursor: pointer;
}

.table-checkin-btn {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: relative;
  left: -16px;
  margin-top: 4px;
  top: 14px;
}

.table-checkin-btn.green {
  background-color: #08b56e;
}

.table-checkin-btn.orange {
  background-color: #f1920f;
}

td,
th {
  padding: 4px 36px 4px 14px !important;
}

tr:hover {
  background-color: white !important;
}

@media only screen and (max-width: 1120px) {
  .table-head-title.activity,
  .table-head-title.member {
    width: 16%;
  }

  .table-head-title.checkedIn,
  .table-head-title.checkedOut {
    width: 13%;
  }

  td,
  th {
    padding: 4px 16px 4px 4px !important;
  }

  .table-checkin-btn {
    left: -10px;
  }
}

.title {
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: normal !important;

  color: #02081b;
}

.search-box {
  position: absolute !important;
  width: 169px;
  height: 40px;
  right: 175px;
  margin-top: 0px !important;
  background: #ffffff;
}

.common_button {
  margin: 1% !important;
  float: right;
}

.record_data {
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 13px !important;
  line-height: normal !important;

  color: #687fa3 !important;
}

.close_icon {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.modal-title {
  margin: 8px !important;
}

.modal_text {
  margin-left: 8px;
  margin-right: 8px;
}

.status-text {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  margin-left: 4px;
}

.filter-text {
  float: right;
  margin: 8px;
  cursor: pointer;
}

.send {
  margin-right: 20px !important;
}

.add_note {
  color: #687fa3;
  position: absolute;
  cursor: pointer;
  margin-top: 3px;
}

.checkbox_width {
  width: 30px;
}

.member_activity_grid {
  width: 100%;
  margin-top: 6px !important;
}

.no_data {
  width: 125px;
}

.add_note_grid {
  height: 100px;
}

.send_merit {
  margin-top: 10px !important;
  float: right;
}

.table-checkout-subTitle {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 12px;
  color: #33415c;
}

.divider {
  color: #147df4 !important;
  height: 5px;
  margin-top: 15px !important;
  margin-left: -15px !important;
  width: 430px;
}

.merit_details {
  background: #f7faff;
  width: 416px;
}

.merit_container {
  background: #ffffff;
}

.common_padding_left {
  padding-left: 105px;
}

.search_button {
  height: 100%;
}

.all_checkins_action_menu > ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-radius: 4px;
  box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 1), 0px 0px 0px 0px rgba(0, 0, 0, 1), 0px 0px 4px 2px rgba(0, 0, 0, 1);
  padding-top: 8px;
  padding-bottom: 8px;
  height: 210px;
  width: 111px;
  text-align: center;
  display: grid;
  align-items: center;
  justify-content: center;
}

.checkin-table-header span.ant-table-column-title {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-size: 12px;
  color: #687fa3;
}

.check_ins_log_table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.04);
  box-shadow: 0px 1.5px rgba(0, 0, 0, 0.1);
}

.check_ins_log_table .ant-checkbox-inner {
  border: 2px solid rgba(0, 0, 0, 0.54) !important;
  height: 18px !important;
  width: 18px !important;
}

.check_ins_log_table .ant-checkbox-checked .ant-checkbox-inner {
  border: none !important;
  background-color: #36802d !important;
}

.check_ins_log_table .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: gray !important;
}

.check_ins_log_table .ant-table-thead > tr:first-child > th:first-child {
  border: 1px solid #147df4;
  border-right: none;
  border-radius: 4px 0 0 4px;
}

.check_ins_log_table .ant-table-thead > tr > th.member_title {
  border: 1px solid #147df4;
  border-left: none;
  padding: 0 !important;
  height: 40px;
  border-radius: 0 4px 4px 0;
}

.check_ins_log_table .member_title .ant-table-column-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.check_ins_log_table .ant-table-thead .member_title .ant-table-header-column {
  display: block;
}

.delete_modal_wrapper .ant-modal-title {
  font-family: Lato;
  text-align: center;
  color: #687fa3;
  font-size: 14px;
  font-style: bold;
  letter-spacing: 1%;
}
.delete_modal_wrapper .ant-modal-header {
  border: none;
  height: 45px;
}
.delete_modal_wrapper .ant-modal-body {
  padding: 12px;
}
.delete_modal_wrapper .ant-modal-content {
  height: 297px;
}

.delete_header {
  text-align: center;
  color: #02081b;
  font-family: Lato;
  height: 40px;
  font-size: 18px;
}
.delete_description {
  text-align: center;
  color: #a6b8cc;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 1%;
  width: 70%;
  margin-left: 13%;
}

.checkout_column {
  padding-bottom: 12px;
  text-align: "left";
}

.checkout_column:hover {
  background-color: #f5f5f5 !important;
}

.ant-collapse-item .ant-collapse-header {
  padding-left: 0 !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: inline !important;
  position: static !important;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-bottom: 0px;
}

.common_padding_left {
  padding-left: 105px;
}

.checkinlog_cursor_pointer {
  margin-left: 7px;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.table-content-title {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: #33415c;
  word-break: break-all;
}

.table-checkin-btn {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: relative;
  left: -16px;
  margin-top: 4px;
  top: 14px;
}

.all_offline_action_menu > ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-radius: 4px;
  box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 1), 0px 0px 0px 0px rgba(0, 0, 0, 1), 0px 0px 4px 2px rgba(0, 0, 0, 1);
  padding-top: 8px;
  padding-bottom: 8px;
  height: 210px;
  width: 111px;
  text-align: center;
  display: grid;
  align-items: center;
  justify-content: center;
}

.checkin-table-header span.ant-table-column-title {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-size: 12px;
  color: #687fa3;
}

.table-content-title-pointer {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: #33415c;
  word-break: break-all;
  cursor: pointer;
}

.table-content-subTitle {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 12px;
  color: #a6b8cc;
}

.orphaned_check_out_action_menu > ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-radius: 4px;
  box-shadow: 0px 5px 10px 3px rgba(0, 0, 0, 1), 0px 0px 0px 0px rgba(0, 0, 0, 1), 0px 0px 4px 2px rgba(0, 0, 0, 1);
  width: 111px;
  text-align: center;
  display: grid;
  align-items: center;
  justify-content: center;
}

.orphaned_check_out_log_table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.04);
  box-shadow: 0px 1.5px rgba(0, 0, 0, 0.1);
}

.orphaned_check_out_log_table .ant-checkbox-inner {
  border: 2px solid rgba(0, 0, 0, 0.54) !important;
  height: 18px !important;
  width: 18px !important;
}

.orphaned_check_out_log_table .ant-checkbox-checked .ant-checkbox-inner {
  border: none !important;
  background-color: #36802d !important;
}

.orphaned_check_out_log_table .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: gray !important;
}

.orphaned_check_out_log_table .ant-table-thead > tr:first-child > th:first-child {
  border: 1px solid #147df4;
  border-right: none;
  border-radius: 4px 0 0 4px;
}

.orphaned_check_out_log_table .ant-table-thead > tr > th.member_title {
  border: 1px solid #147df4;
  border-left: none;
  padding: 0 !important;
  height: 40px;
  border-radius: 0 4px 4px 0;
}

.orphaned_check_out_log_table .member_title .ant-table-column-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.orphaned_check_out_log_table .ant-table-thead .member_title .ant-table-header-column {
  display: block;
}

.match_session_modal_wrapper .ant-modal-title {
  font-family: Lato;
  text-align: center;
  color: #687fa3;
  font-size: 14px;
  font-style: bold;
  letter-spacing: 1%;
}
.match_session_modal_wrapper .ant-modal-header {
  border: none;
  height: 45px;
}
.match_session_modal_wrapper .ant-modal-body {
  padding: 12px;
}
.match_session_modal_wrapper .ant-modal-content {
  min-height: 60%;
  min-width: 70%;
}
